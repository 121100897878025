import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-sdk/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Welcome to our developer guidance. These pages are intended for use by software developers, either as a reference, or as a walkthrough for first-timers building workflows on the KOPE platform.`}</p>
    </PageDescription>
    <h4>{`Feeling Lost? See Our Other Guidance`}</h4>
    <ul>
      <li parentName="ul">{`For `}<em parentName="li">{`non-coders`}</em>{`, check out our `}<a parentName="li" {...{
          "href": "'/'"
        }}>{`User Documentation`}</a>{` on how to use the KOPE platform.`}</li>
      <li parentName="ul">{`For `}<em parentName="li">{`software developers`}</em>{`, don’t forget our `}<a parentName="li" {...{
          "href": "'/'"
        }}>{`API Reference`}</a>{`.`}</li>
    </ul>
    <h2>{`Contents`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/setup/visual-studio-setup"
          }}><strong parentName="a">{`Setup`}</strong></a>{` `}<br />{`We’ll walk you through the full initial setup: which libraries KOPE relies upon, what they do and which versions we recommend you install.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/authorization"
          }}><strong parentName="a">{`Authorization`}</strong></a>{` `}<br />{`How to confirm your identity.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/development/kope-development-workflow"
          }}><strong parentName="a">{`Development`}</strong></a>{` `}<br />{`This section explains the structure of KOPE, the key concepts you’ll need to be familiar with when building activities.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/deployment/introduction"
          }}><strong parentName="a">{`Deployment`}</strong></a>{` `}<br />{`How to deploy your Activity code to the platform.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/help/contact"
          }}><strong parentName="a">{`Help`}</strong></a>{` `}<br />{`How to get help if you’re stuck.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/about"
          }}><strong parentName="a">{`About`}</strong></a>{` `}<br />{`About this guide.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      